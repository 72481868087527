import React from 'react';
// import DMRVChart from '../dmrvChart/dmrvChart';
// import GraphicExchangePage from '../GraphicExchangePage/GraphicExchangePage';
import { Container, Grid } from '@material-ui/core';
import IFrameUI from '../iFrameUI/iFrameUI';

const Test = () => (
  <div>
    <br />
    <br />
    <br />
    <br />
    <br />
    {/* <DMRVChart></DMRVChart> */}

    {/* <GraphicExchangePage /> */}
    <Container maxWidth="md">
      <IFrameUI />
    </Container>
  </div>
);

export default Test;
