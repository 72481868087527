import React from 'react';
import { Typography, Box, Container } from '@material-ui/core';
import { PoliciesLogic, useStyles } from './policies.module';

const PoliciesPage = () => {
  const { translator } = PoliciesLogic();
  const classes = useStyles();

  return (
    <Container maxWidth="md" className={classes.container}>
      <Box py={4}>
        <Typography variant="h3" gutterBottom className={classes.title}>
          {translator('policies.title')}
        </Typography>

        <Typography variant="h5" gutterBottom className={classes.smallHeading}>
          {translator('policies.standards.title')}
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.normalText}>
          {translator('policies.standards.intro')}
        </Typography>
        <ul>
          <li className={classes.listText}>
            {translator('policies.standards.declaration')}
          </li>
          <li className={classes.listText}>
            {translator('policies.standards.principles')}
          </li>
          <li className={classes.listText}>
            {translator('policies.standards.covenant.civil')}
          </li>
          <li className={classes.listText}>
            {translator('policies.standards.covenant.economic')}
          </li>
          <li className={classes.listText}>
            {translator('policies.standards.compact')}
          </li>
          <li className={classes.listText}>
            {translator('policies.standards.sdg')}
          </li>
          <li className={classes.listText}>
            {translator('policies.standards.ilo')}
          </li>
        </ul>

        <Typography variant="h5" gutterBottom className={classes.smallHeading}>
          {translator('policies.general.title')}
        </Typography>
        <ul>
          <li className={classes.normalText}>
            <strong>{translator('policies.general.respect.heading')}</strong>:{' '}
            {translator('policies.general.respect.text')}
          </li>
          <li className={classes.normalText}>
            <strong>
              {translator('policies.general.transparency.heading')}
            </strong>
            : {translator('policies.general.transparency.text')}
          </li>
          <li className={classes.normalText}>
            <strong>{translator('policies.general.innovation.heading')}</strong>
            : {translator('policies.general.innovation.text')}
          </li>
        </ul>

        <Typography variant="h5" gutterBottom className={classes.smallHeading}>
          {translator('policies.diversity.title')}
        </Typography>
        <ul>
          <li className={classes.normalText}>
            <strong>
              {translator('policies.diversity.diversity.heading')}
            </strong>
            : {translator('policies.diversity.diversity.text')}
          </li>
          <li className={classes.normalText}>
            <strong>{translator('policies.diversity.equity.heading')}</strong>:{' '}
            {translator('policies.diversity.equity.text')}
          </li>
          <li className={classes.normalText}>
            <strong>
              {translator('policies.diversity.inclusion.heading')}
            </strong>
            : {translator('policies.diversity.inclusion.text')}
          </li>
        </ul>

        <Typography variant="h5" gutterBottom className={classes.smallHeading}>
          {translator('policies.slavery.title')}
        </Typography>
        <ul>
          <li className={classes.normalText}>
            <strong>{translator('policies.slavery.forced.heading')}</strong>:{' '}
            {translator('policies.slavery.forced.text')}
          </li>
          <li className={classes.normalText}>
            <strong>{translator('policies.slavery.conditions.heading')}</strong>
            : {translator('policies.slavery.conditions.text')}
          </li>
          <li className={classes.normalText}>
            <strong>{translator('policies.slavery.child.heading')}</strong>:{' '}
            {translator('policies.slavery.child.text')}
          </li>
          <li className={classes.normalText}>
            <strong>
              {translator('policies.slavery.exploitation.heading')}
            </strong>
            : {translator('policies.slavery.exploitation.text')}
          </li>
        </ul>

        <Typography variant="h5" gutterBottom className={classes.smallHeading}>
          {translator('policies.violence.title')}
        </Typography>
        <ul>
          <li className={classes.normalText}>
            <strong>{translator('policies.violence.zero.heading')}</strong>:{' '}
            {translator('policies.violence.zero.text')}
          </li>
          <li className={classes.normalText}>
            <strong>{translator('policies.violence.safe.heading')}</strong>:{' '}
            {translator('policies.violence.safe.text')}
          </li>
        </ul>

        <Typography variant="h5" gutterBottom className={classes.smallHeading}>
          {translator('policies.communities.title')}
        </Typography>
        <ul>
          <li className={classes.normalText}>
            <strong>
              {translator('policies.communities.consent.heading')}
            </strong>
            : {translator('policies.communities.consent.text')}
          </li>
          <li className={classes.normalText}>
            <strong>
              {translator('policies.communities.benefits.heading')}
            </strong>
            : {translator('policies.communities.benefits.text')}
          </li>
          <li className={classes.normalText}>
            <strong>
              {translator('policies.communities.environmental.heading')}
            </strong>
            : {translator('policies.communities.environmental.text')}
          </li>
        </ul>

        <Typography variant="h5" gutterBottom className={classes.smallHeading}>
          {translator('policies.corruption.title')}
        </Typography>
        <ul>
          <li className={classes.normalText}>
            <strong>{translator('policies.corruption.bribery.heading')}</strong>
            : {translator('policies.corruption.bribery.text')}
          </li>
          <li className={classes.normalText}>
            <strong>
              {translator('policies.corruption.coercion.heading')}
            </strong>
            : {translator('policies.corruption.coercion.text')}
          </li>
          <li className={classes.normalText}>
            <strong>{translator('policies.corruption.fraud.heading')}</strong>:{' '}
            {translator('policies.corruption.fraud.text')}
          </li>
        </ul>

        <Typography variant="h5" gutterBottom className={classes.smallHeading}>
          {translator('policies.technology.title')}
        </Typography>
        <ul>
          <li className={classes.normalText}>
            <strong>{translator('policies.technology.use.heading')}</strong>:{' '}
            {translator('policies.technology.use.text')}
          </li>
          <li className={classes.normalText}>
            <strong>
              {translator('policies.technology.blockchain.heading')}
            </strong>
            : {translator('policies.technology.blockchain.text')}
          </li>
          <li className={classes.normalText}>
            <strong>{translator('policies.technology.labor.heading')}</strong>:{' '}
            {translator('policies.technology.labor.text')}
          </li>
        </ul>

        <Typography variant="h5" gutterBottom className={classes.smallHeading}>
          {translator('policies.data.title')}
        </Typography>
        <ul>
          <li className={classes.normalText}>
            <strong>{translator('policies.data.confidential.heading')}</strong>:{' '}
            {translator('policies.data.confidential.text')}
          </li>
          <li className={classes.normalText}>
            <strong>{translator('policies.data.rights.heading')}</strong>:{' '}
            {translator('policies.data.rights.text')}
          </li>
        </ul>

        <Typography variant="h5" gutterBottom className={classes.smallHeading}>
          {translator('policies.implementation.title')}
        </Typography>
        <ul>
          <li className={classes.normalText}>
            <strong>
              {translator('policies.implementation.training.heading')}
            </strong>
            : {translator('policies.implementation.training.text')}
          </li>
          <li className={classes.normalText}>
            <strong>
              {translator('policies.implementation.evaluation.heading')}
            </strong>
            : {translator('policies.implementation.evaluation.text')}
          </li>
        </ul>
        <Typography variant="body1" gutterBottom className={classes.normalText}>
          {translator('policies.conclusion')}
        </Typography>
      </Box>
    </Container>
  );
};

export default PoliciesPage;
