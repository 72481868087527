import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  AppBar,
  Tabs,
  Tab,
  Toolbar,
  Menu,
  MenuItem,
  IconButton,
  Button,
  ListItemText,
  useTheme,
  createStyles,
} from '@material-ui/core';
import TranslateIcon from '@material-ui/icons/Language';
import MenuIcon from '@material-ui/icons/Menu';
import PersonIcon from '@material-ui/icons/Person';
import { withStyles } from '@material-ui/core/styles';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import PeopleIcon from '@material-ui/icons/People';
import EcoIcon from '@material-ui/icons/Eco';
import RateReviewIcon from '@material-ui/icons/RateReview';
import CachedIcon from '@material-ui/icons/Cached';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import Brightness7Icon from '@material-ui/icons/Brightness7';
const { LogoDark, LogoLight, useStyles, BarLogic } = require('./bar.module');

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) =>
  createStyles({
    root: {
      '&$selected': {
        backgroundColor: '#40ad6e',
        '&:hover': {
          backgroundColor: '#40ad6e',
        },
      },
      '&:hover': {
        backgroundColor: '#40ad6e',
      },
    },
    selected: {},
  })
)(MenuItem);

const Bar = (props) => {
  const theme = useTheme();
  const classes = useStyles();
  const location = useLocation();
  const {
    account,
    anchorEl,
    anchorPersonIcon,
    anchorLanguageIcon,
    hasSession,
    isMobile,
    path,
    pathSignup,
    signup,
    translator,
    value,
    values,
    handleClick,
    handleClickPersonIcon,
    handleClickTab,
    handleClose,
    handleClosePersonIcon,
    changeLanguage,
    goToExchange,
    onClick,
    handleOpenLanguageIcon,
    handleCloseLanguageIcon,
  } = BarLogic();
  const isHome = location.pathname === '/';
  const backgroundColor = isHome ? '#041370' : 'transparent';

  const menu_language = () => (
    <>
      <TranslateIcon
        aria-haspopup="true"
        variant="contained"
        aria-label="menu"
        className={
          theme.palette.mode === 'light'
            ? classes.IconButton
            : classes.IconButtonLight
        }
        onClick={handleOpenLanguageIcon}
      >
        <MenuIcon />
      </TranslateIcon>
      <StyledMenu
        anchorEl={anchorLanguageIcon}
        keepMounted
        open={Boolean(anchorLanguageIcon)}
        onClose={handleCloseLanguageIcon}
        className={
          theme.palette.mode === 'light' ? classes.active : classes.activeLight
        }
      >
        <div>
          <StyledMenuItem>
            <Button
              onClick={changeLanguage('es')}
              className={
                values.language === 'es'
                  ? classes.selectedText
                  : classes.menuTextLang
              }
            >
              ES
            </Button>
          </StyledMenuItem>
          <StyledMenuItem>
            <Button
              onClick={changeLanguage('en')}
              className={
                values.language === 'en'
                  ? classes.selectedText
                  : classes.menuTextLang
              }
            >
              EN
            </Button>
          </StyledMenuItem>
{/*           <StyledMenuItem>
            <Button
              onClick={changeLanguage('fr')}
              className={
                values.language === 'fr'
                  ? classes.selectedText
                  : classes.textLang
              }
            >
              Fr
            </Button>
          </StyledMenuItem> */}
        </div>
      </StyledMenu>
    </>
  );

  const buttons_responsive = () => (
    <>
      <a className={classes.link} onClick={goToExchange}>
        <StyledMenuItem>
          <ListItemIcon>
            <CachedIcon
              fontSize="small"
              className={
                theme.palette.mode === 'light'
                  ? classes.IconButton
                  : classes.IconButtonLight
              }
            />
          </ListItemIcon>
          <ListItemText primary={translator('bar.exchange')} />
        </StyledMenuItem>
      </a>
      <Link className={classes.link} onClick={props.changeMode.toggleColorMode}>
        <StyledMenuItem>
          {theme.palette.mode === 'dark' ? (
            <>
              <ListItemIcon>
                <Brightness7Icon className={classes.IconButtonLight} />
              </ListItemIcon>
              <ListItemText primary={translator('bar.light_mode')} />
            </>
          ) : (
            <>
              <ListItemIcon>
                <Brightness4Icon className={classes.IconButton} />
              </ListItemIcon>
              <ListItemText primary={translator('bar.dark_mode')} />
            </>
          )}
        </StyledMenuItem>
      </Link>
    </>
  );

  const buttons_bar = () => (
    <>
      <Tabs
        className={classes.tabsContainer}
        onChange={handleClickTab}
        indicatorColor="secondary"
        value={value}
      ></Tabs>
      <IconButton
        sx={{ ml: 1 }}
        onClick={props.changeMode.toggleColorMode}
        className={
          theme.palette.mode === 'light'
            ? classes.IconButton
            : classes.IconButtonLight
        }
        aria-label="light-dark button"
      >
        {theme.palette.mode === 'dark' ? (
          <Brightness7Icon />
        ) : (
          <Brightness4Icon />
        )}
      </IconButton>
    </>
  );

  const with_session = () => (
    <Link className={classes.link} to="/login">
      <StyledMenuItem>
        <ListItemIcon>
          <ExitToAppIcon
            fontSize="small"
            className={
              theme.palette.mode === 'light'
                ? classes.IconButton
                : classes.IconButtonLight
            }
          />
        </ListItemIcon>
        <ListItemText
          onClick={onClick}
          primary={translator('bar.close_session')}
        />
      </StyledMenuItem>
    </Link>
  );

  const without_session = () => (
    <Link className={classes.link} to={pathSignup}>
      <StyledMenuItem>
        <ListItemIcon>
          <AssignmentIndIcon
            fontSize="small"
            className={
              theme.palette.mode === 'light'
                ? classes.IconButton
                : classes.IconButtonLight
            }
          />
        </ListItemIcon>
        <ListItemText primary={signup} />
      </StyledMenuItem>
    </Link>
  );

  const mainLinks = () => (
    <div className={classes.mainLinks}>
      <Link to="/egreen">
        <Button
          className={
            location.pathname === '/egreen' ? classes.textGreen : classes.text
          }
        >
          {translator('aboutUsSection.title')}
        </Button>
      </Link>
      <Link to="/projects">
        <Button
          className={
            location.pathname === '/projects' ? classes.textGreen : classes.text
          }
        >
          {translator('breadcrumbs_projects')}
        </Button>
      </Link>
      <Link to="/carbonManagement">
        <Button
          className={
            location.pathname === '/carbonManagement'
              ? classes.textGreen
              : classes.text
          }
        >
          {translator('bar.carbon_managment')}
        </Button>
      </Link>
      <Link to="/carbonCredits">
        <Button
          className={
            location.pathname === '/carbonCredits'
              ? classes.textGreen
              : classes.text
          }
        >
          {translator('dashboard.carbon_credits')}
        </Button>
      </Link>
      <Link to="/pricing">
        <Button
          className={
            location.pathname === '/pricing' ? classes.textGreen : classes.text
          }
        >
          {translator('investment&trading')}
        </Button>
      </Link>
      <Link to="/blog">
        <Button
          className={
            location.pathname === '/blog' ? classes.textGreen : classes.text
          }
        >
          Blog
        </Button>
      </Link>
    </div>
  );

  return (
    <div width="320px">
      <AppBar
        style={
          theme.palette.mode === 'light'
            ? { backgroundColor: '#FFF' }
            : { backgroundColor: '#041370' }
        }
      >
        <Toolbar className={classes.appBar}>
          <div nowrap={'true'} className={classes.egreenContainer} align="left">
            <Link to="/">
              <img
                className={classes.egreenLogo}
                src={theme.palette.mode === 'light' ? LogoLight : LogoDark}
                alt="eGreen Logo"
              />
            </Link>
          </div>
          {!isHome && !isMobile && mainLinks()}
          {isHome && <>{!isMobile && mainLinks()}</>}
          <div className={classes.flexItem}>
            {!isMobile && buttons_bar()}
            {menu_language()}
            <IconButton
              aria-haspopup="true"
              variant="contained"
              aria-label="menu"
              className={classes.IconButtonLight}
              onClick={handleClick}
            >
              {isMobile && <MenuIcon />}
            </IconButton>
            <StyledMenu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              className={
                theme.palette.mode === 'light'
                  ? classes.active
                  : classes.activeLight
              }
            >
              {
                <div>
                  <Link className={classes.link} to="/egreen">
                    <StyledMenuItem>
                      <ListItemIcon>
                        <PeopleIcon
                          fontSize="small"
                          className={
                            theme.palette.mode === 'light'
                              ? classes.IconButton
                              : classes.IconButtonLight
                          }
                        />
                      </ListItemIcon>
                      <Link className={classes.link} to="/egreen">
                        <ListItemText primary={translator('bar.us')} />
                      </Link>
                    </StyledMenuItem>
                  </Link>
                  <Link className={classes.link} to="/CarbonManagement">
                    <StyledMenuItem>
                      <ListItemIcon>
                        <PeopleIcon
                          fontSize="small"
                          className={
                            theme.palette.mode === 'light'
                              ? classes.IconButton
                              : classes.IconButtonLight
                          }
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={translator('bar.carbon_managment')}
                      />
                    </StyledMenuItem>
                  </Link>
                  <Link className={classes.link} to="/coins">
                    <StyledMenuItem>
                      <ListItemIcon>
                        <EcoIcon
                          fontSize="small"
                          className={
                            theme.palette.mode === 'light'
                              ? classes.IconButton
                              : classes.IconButtonLight
                          }
                        />
                      </ListItemIcon>
                      <ListItemText primary={translator('bar.egreen_coin')} />
                    </StyledMenuItem>
                  </Link>
                  <Link className={classes.link} to="/projects">
                    <StyledMenuItem>
                      <ListItemIcon>
                        <EcoIcon
                          fontSize="small"
                          className={
                            theme.palette.mode === 'light'
                              ? classes.IconButton
                              : classes.IconButtonLight
                          }
                        />
                      </ListItemIcon>
                      <ListItemText primary={translator('bar.projects')} />
                    </StyledMenuItem>
                  </Link>
                  <Link className={classes.link} to="/blog">
                    <StyledMenuItem>
                      <ListItemIcon>
                        <RateReviewIcon
                          fontSize="small"
                          className={
                            theme.palette.mode === 'light'
                              ? classes.IconButton
                              : classes.IconButtonLight
                          }
                        />
                      </ListItemIcon>
                      <ListItemText primary={translator('bar.egreen_blog')} />
                    </StyledMenuItem>
                  </Link>
                  {isMobile && buttons_responsive()}
                </div>
              }
            </StyledMenu>
            <PersonIcon
              aria-haspopup="true"
              variant="contained"
              className={
                theme.palette.mode === 'light'
                  ? classes.IconButton
                  : classes.IconButtonLight
              }
              onClick={handleClickPersonIcon}
            >
              <MenuIcon />
            </PersonIcon>
            <StyledMenu
              anchorEl={anchorPersonIcon}
              keepMounted
              open={Boolean(anchorPersonIcon)}
              onClose={handleClosePersonIcon}
              className={
                theme.palette.mode === 'light'
                  ? classes.activePersonIcon
                  : classes.activePersonIconLight
              }
            >
              {
                <div>
                  <Link className={classes.link} to={path}>
                    <StyledMenuItem>
                      <ListItemIcon>
                        <AccountCircleIcon
                          fontSize="small"
                          className={
                            theme.palette.mode === 'light'
                              ? classes.IconButton
                              : classes.IconButtonLight
                          }
                        />
                      </ListItemIcon>
                      <ListItemText primary={account} />
                    </StyledMenuItem>
                  </Link>
                  {hasSession ? with_session() : without_session()}
                </div>
              }
            </StyledMenu>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Bar;
